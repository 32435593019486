import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import Layout from '@components/layouts/default/Layout'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import ButtonLink from '@components/ui/buttons/ButtonLink'
import { color, breakpoint } from '@configs/utilities'

const Error = ({ intl }) => {
  return (
    <Layout pageName="error">
      <SectionWrapper>
        <Wrapper>
          <StaticImage src="../assets/images/error/404-not-found.jpg" alt="Error page" />
          <Content>
            <h1>{intl.formatMessage({ id: 'error.title' })}</h1>
            <p>{intl.formatMessage({ id: 'error.text' })}</p>
            <ButtonLink to="/" buttonText={intl.formatMessage({ id: 'buttons.home' })} mdfontsize={'20px'} />
          </Content>
        </Wrapper>
      </SectionWrapper>
    </Layout>
  )
}

export default injectIntl(Error)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 10px;
  color: ${color.font};

  h1 {
    font-size: 22px;
    font-weight: 500;

    @media screen and (min-width: ${breakpoint.md}) {
      font-size: 28px;
    }
    @media screen and (min-width: ${breakpoint.lg}) {
      font-size: 34px;
    }
    @media screen and (min-width: ${breakpoint.xl}) {
      font-size: 40px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 30px;

    @media screen and (min-width: ${breakpoint.md}) {
      font-size: 20px;
    }
    @media screen and (min-width: ${breakpoint.lg}) {
      font-size: 23px;
    }
    @media screen and (min-width: ${breakpoint.xl}) {
      font-size: 26px;
    }
  }
`
