import React from 'react'
import styled from 'styled-components'
import { color, breakpoint, transition } from '@configs/utilities'
import { Link } from 'gatsby-plugin-react-intl'
import { ExplainButton } from '@components/ui/typography/Typography'

const ButtonLink = ({ children, ...props }) => {
  function renderBtnText(props) {
    return props.icon ? (
      <ButtonTextWrapper>
        {props.icon}
        {props.buttonText}
      </ButtonTextWrapper>
    ) : (
      props.buttonText
    )
  }

  return props.external ? (
    <ButtonWrapper align={props.align}>
      <ExternalLink
        href={props.href}
        target={props.targetBlank ? '_blank' : '_self'}
        rel="noopener noreferrer"
        background={props.buttonBackground}
        color={props.buttonColor}
        smfontsize={props.smfontsize}
        mdfontsize={props.mdfontsize}
        border={props.border}
      >
        {renderBtnText(props)}
      </ExternalLink>
      {props.explain ? <ExplainButton>{props.explainText}</ExplainButton> : null}
    </ButtonWrapper>
  ) : (
    <ButtonWrapper align={props.align}>
      <InternalLink
        to={props.to}
        background={props.buttonBackground}
        color={props.buttonColor}
        smfontsize={props.smfontsize}
        mdfontsize={props.mdfontsize}
        border={props.border}
        onClick={props.onClick}
      >
        {renderBtnText(props)}
      </InternalLink>
      {props.explain ? <ExplainButton>{props.explainText}</ExplainButton> : null}
    </ButtonWrapper>
  )
}

export default ButtonLink

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;

  @media screen and (min-width: ${breakpoint.lg}) {
    align-items: ${(props) => props.align || 'center'};
  }

  a {
    min-width: 110px;
    padding: 10px 20px;
    border-radius: 8px;
    transition: ${transition.default};
    font-weight: 600;
    text-align: center;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    @media screen and (min-width: ${breakpoint.md}) {
      min-width: 140px;
      padding: 12px 30px;
    }
  }
`

const ButtonTextWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
`

const ExternalLink = styled.a`
  font-size: ${(props) => props.smfontsize || '14px'};
  color: ${(props) => props.color || color.white};
  background: ${(props) => props.background || color.blue};
  box-shadow: ${(props) => props.border || 'none'};

  @media screen and (min-width: ${breakpoint.md}) {
    font-size: ${(props) => props.mdfontsize || '16px'};
  }
`

const InternalLink = styled(Link)`
  font-size: ${(props) => props.smfontsize || '14px'};
  color: ${(props) => props.color || color.white};
  background: ${(props) => props.background || color.blue};
  box-shadow: ${(props) => props.border || 'none'};

  @media screen and (min-width: ${breakpoint.md}) {
    font-size: ${(props) => props.mdfontsize || '16px'};
  }
`
